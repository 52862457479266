import React from 'react'
import TopTechnologies from './TopTechnologies'
import Baner from './Baner'
import Trending from './Trending'
import Why from './Why'
import Spec from './Spec'
function Technologies() {
  return (
    <>
    <Baner/>
    
    <Trending/>
    <h1 className='top-tech'>Top Technologies Offered</h1>
    <TopTechnologies/>
    {/* <TopTechnologies/>
    <TopTechnologies/> */}
    <Why/>
    </>
  )
}

export default Technologies
