import React from 'react'
import TopTrending from './TopTrending'

function Trending() {
  return (
    <div>
        <h1 className='top-tech'>Trending Courses</h1>
      <TopTrending/>
    </div>
  )
}

export default Trending
