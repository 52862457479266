import React from 'react';
import imge from "../assets/images/banner.png";
import projects from "../assets/images/projects.jpg";
import time from "../assets/images/time.png";
import placement from "../assets/images/placement.jpg";

function Why() {
  return (
    <div className="why-container">
      {/* Header Section */}
      <div className="header">
        <h1 className="headerText">HERE, EVERY DREAM HAS THE POWER TO INSPIRE!</h1>
      </div>

      {/* Image and Text Overlay Section */}
      <div className="imageSection">
        <div className="overlayText">
          <h2 className="overlayHeading">Why ShillHaara?</h2>
        </div>
      </div>

      {/* Card Section */}
      <div className="cardSection">
        {/* Card 1: Convenient Study Time */}
        <div className="card">
          <div className="icon">
            <img src={time} alt="Convenient Study Time" className="cardIcon" />
          </div>
          <h3 className="cardTitle">Convenient Study Time</h3>
          <p className="cardDescription">
            Flexible learning schedules tailored to your availability. Study at your own pace, anytime and anywhere.
          </p>
        </div>

        {/* Card 2: Placement Assistance */}
        <div className="card">
          <div className="icon placement">
            <img src={placement} alt="Placement Assistance" className="cardIcon" />
          </div>
          <h3 className="cardTitle placement-desc">Placement Assistance</h3>
          <p className="cardDescription">
            Guaranteed support in securing job opportunities. Receive guidance and resources to land your dream job.
          </p>
        </div>

        {/* Card 3: Hands-On Projects */}
        <div className="card">
          <div className="icon project">
            <img src={projects} alt="Hands-On Projects" className="cardIcon" />
          </div>
          <h3 className="cardTitle pro-des">Hands-On Projects</h3>
          <p className="cardDescription ">
            Gain real-world experience through practical projects. Enhance your skills by working on industry-relevant tasks.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Why;
