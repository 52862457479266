import React from 'react'
import banner from "../assets/images/banner.png"
function Baner() {
  return (
    <div className='banner-container'>
       <img src={banner} alt="" className='baner-img' />
    </div>
  )
}

export default Baner

