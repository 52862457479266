import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function DemandSingle() {
  const { demandId } = useParams();
  const [demandCourse, setDemandCourse] = useState(null);
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState('courseObj'); // Set 'courseObj' as default

  useEffect(() => {
    const fetchDemandCourse = async () => {
      try {
        const response = await axios.get(`http://localhost:3300/user/view-demand/${demandId}`);
        setDemandCourse(response.data.data); 
        console.log(response.data.data);
      } catch (error) {
        setError('Error fetching course details.');
      }
    };
    fetchDemandCourse();
  }, [demandId]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!demandCourse) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Banner */}
      <div>
        <img src={demandCourse.bannerImage} alt="Banner" style={{ width: "100%", height: "auto" }} />
      </div>

      <div>
        <h1 className="overview-main">Course overview</h1>
        <p className="eda-desc">{demandCourse.overview}</p>
      </div>

      {/* Why Python Development */}
      <div className='why-div'>
        <h1 className="overview-main1">Why {demandCourse.title}?</h1>
        <p className="eda-desc">{demandCourse.why}</p>
        <ul className="eda-desc" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
          {demandCourse.whyList?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>

      {/* Buttons to navigate between sections */}
      <div className='sing-btn-div'>
        <button onClick={() => setActiveSection('courseObj')} className='sing-btns'>Course Objective</button>
        <button onClick={() => setActiveSection('scope')} className='sing-btns'>Scope of Course</button>
        <button onClick={() => setActiveSection('career')} className='sing-btns'>Course Opportunities</button>
      </div>

      {/* Conditionally render sections based on activeSection */}
      {activeSection === 'courseObj' && (
        <div>
          <h1 className="overview-main1">Course Objective</h1>
          <p className="eda-desc">{demandCourse.courseObj}</p>
          <ul className="eda-desc" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
            {demandCourse.courseObjList?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}

      {activeSection === 'scope' && (
        <div>
          <h1 className="overview-main1">Scope of Course</h1>
          <p className="eda-desc">{demandCourse.scope}</p>
          <ul className="eda-desc" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
            {demandCourse.scopeList?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}

      {activeSection === 'career' && (
        <div>
          <h1 className="overview-main1">Career Opportunities</h1>
          <p className="eda-desc">{demandCourse.career}</p>
          <ul className="eda-desc" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
            {demandCourse.careerList?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}

      {/* Career Description */}
      <div className='desc-career'>
        <p className="eda-desc">{demandCourse.careerdesc}</p>
      </div>

      {/* Enroll Today */}
      <div>
        <p className="eda-desc">{demandCourse.enrolltoday}</p>
      </div>
    </div>
  );
}

export default DemandSingle;
