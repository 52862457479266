import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams ,useNavigate } from "react-router-dom";


function ViewSingle() {
  const { courseId } = useParams(); 
  const [course, setCourse] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (courseId) {
      axios
        .get(`http://localhost:3300/user/view-maincourse/${courseId}`)
        .then((response) => {
          setCourse(response.data.data);
          console.log(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching course details:", error);
        });
    }
  }, [courseId]);

  const handleDelete = () => {
    axios
      .delete(`http://localhost:3300/user/delete-maincourse/${courseId}`)
      .then(() => {
        alert("Course deleted successfully.");
        navigate("/admin/addcourse"); 
      })
      .catch((error) => {
        console.error("Error deleting course:", error);
        alert("Failed to delete the course.");
      });
  };


  const handleCourseSelect = () => {
    // Handle navigation to the edit page
    navigate(`/admin/addcourse/edit/${courseId}`);
  };

  if (!course) {
    return <div>Loading...</div>;
  }

  return (
    <div className="viewlist-main">
        <h1 className="title-viewcourse">{course.title}</h1>
      <div className="view-image">
        <p>Baner Image</p>
        <img
          src={course.bannerImage}
          alt="Banner"
          style={{ width: "200px", height: "auto" }}
        />
      </div>
      <div className="view-image">
        <p>MainImage</p>
        <img
          src={course.mainImage}
          alt="main"
          style={{ width: "200px", height: "auto" }}
        />
      </div>
      <div>
        <h1 className="overview-main">Course overview</h1>
        <p className="eda-desc">
          {course.overview || "No overview available for this course."}
        </p>
      </div>
      <div className="eda-form-div">
        <div>
          <h2 className="eda-subhead">Technologies Covered:</h2>
          <p className="eda-desc">
           {course.technologies}
          </p>
          
          <ul className="eda-ul" style={{ listStyle: "disc" }}>
            {course.courses.map((tech, index) => (
              <li key={index}>{tech}</li>
            ))}
          </ul>
        </div>
      </div>
     <div>
     
     <button onClick={handleDelete} className="delete-button">
        Delete Course
      </button>
    
    <button className="edit-button" 
    onClick={() => handleCourseSelect(course._id)} 
    >Edit</button>
   
  
    
     </div>
    </div>
  );
}

export default ViewSingle;
